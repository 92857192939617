import { connect } from 'react-redux';
import Component from './component';

import {
    list as listUserOffers,
    update,
    remove,
    create,
    single,
    presignFile,
    userNotification,
} from 'Redux/modules/admin/userOfferDiagnosticReports/actions';
import { list as listUsers } from 'Redux/modules/admin/users/actions';
import { list as listLocations } from 'Redux/modules/admin/locations/actions';
import { list as listProducts } from 'Redux/modules/admin/products/actions';
import { list as listOffers } from 'Redux/modules/admin/offers/actions'; 

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            listUsers: dispatch(listUsers),
            listUserOffers: dispatch(listUserOffers),
            update: dispatch(update),
            remove: dispatch(remove),
            create: dispatch(create),
            single: dispatch(single),
            presignFile: dispatch(presignFile),
            userNotification: dispatch(userNotification),
            listOffers: dispatch(listOffers),
            listLocations: dispatch(listLocations),
            listProducts: dispatch(listProducts),
        },
    }),
)(Component);