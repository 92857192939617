import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { API_RESOURCE_USER_OFFER } from 'Consts/apiResources';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';

import Editor from 'Components/admin/userOfferDiagnosticReports/Editor';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';

export default class AdminPageDiagnosticReportCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            singleUserOffer: PropTypes.func.isRequired,
        }).isRequired,
    };
    static defaultProps = {};

    state = {
        predefinedState: {},
    };

    defaultQuery = {
        tab: null,
        userOfferId: null,
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        actions.singleUserOffer({ id: queryObject.userOfferId })
            .then(response => {
                this.setState({ 
                    predefinedState: { 
                        userOffer: response.payload[API_RESOURCE_USER_OFFER] || null,
                    },
                });
            });
    }

    render() {
        const { predefinedState } = this.state;
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="page-admin-diagnostic-report-create"
                styles={require('./styles')}
            >
                <PageContent>
                    <ViewHeader
                        title="Nowy raport diagnostyczny"
                    />
                    <Editor
                        location={location}
                        history={history}
                        predefinedState={predefinedState}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}
