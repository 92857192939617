import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { 
    API_RESOURCE_USERS, 
    API_RESOURCE_OFFERS,
    API_RESOURCE_PRODUCTS,
    API_RESOURCE_LOCATIONS,
} from 'Consts/apiResources';
import { ENDPOINT_ADMIN_FILE_PRESIGN } from 'Consts/api';
import { ADMIN_USER_OFFERS_MANAGE } from 'Consts/routes';
import { USER_ROLE_TRAINER } from 'Consts/userRoles';
import { USER_OFFER_SELL_PROPABILITIES } from 'Consts/userOffers'; 

import Logger from 'Services/Logger';

import { withVariables } from 'Utils/string';
import { getFullName as getUserFullName } from 'Utils/user';
import { fromSelectObject, filterKeys } from 'Utils/object';
import { parseQueryToObject } from 'Utils/querystring';
import { getName as getLocationName } from 'Utils/location';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';
import { TAB_DIAGNOSTIC_REPORTS } from 'Components/pages/admin/UserOffersManage/component';

export default class AdminUserOfferDiagnosticReportEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            listUserOffers: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
            create: PropTypes.func.isRequired,
            presignFile: PropTypes.func.isRequired,
            userNotification: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object.isRequired,
        predefinedState: PropTypes.object,
    };

    static defaultProps = {
        predefinedState: {},
    };

    state = {
        formState: {
            files: [],
        },
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data, predefinedState } = this.props;
        const { formState } = this.state;

        if (
            data && JSON.stringify(data) !== JSON.stringify(prevProps.data) ||
            predefinedState && JSON.stringify(predefinedState) !== JSON.stringify(prevProps.predefinedState)
        ) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data || {}),
                },
            }));
        }

        if(prevState?.formState?.productId && JSON.stringify(formState.offerId) !== JSON.stringify(prevState.formState.offerId)) {
            this.setState({
                formState: {
                    ...formState,
                    productId: null,
                },
            });
        }
    }

    dataToFormState = data => {
        const { predefinedState } = this.props;

        return {
            ...data,
            ...predefinedState,
            userOfferId: predefinedState?.userOffer
                ? { value: predefinedState.userOffer.id, label: predefinedState.userOffer.name } 
                : data.userOffer 
                    ? { value: data.userOffer.id, label: data.userOffer.name } 
                    : null,
            leadId: data.lead
                ? { value: data.lead.id, label: getUserFullName(data.lead).label }
                : null,
            offerId: data.offer
                ? { value: data.offer.id, label: data.offer.name }
                : null,
            productId: data.product
                ? { value: data.product.id, label: data.product.name }
                : null,
            locationId: data.location
                ? { value: data.location.id, label: data.location.name }
                : null,
        };
    }

    formStateToData = formState => {
        const { predefinedState } = this.props;

        return {
            ...formState,
            userOfferId: predefinedState.userOffer
                ? predefinedState.userOffer.id
                : null,
            sellProbability: fromSelectObject(formState.sellProbability),
            leadId: fromSelectObject(formState.leadId),
            offerId: fromSelectObject(formState.offerId),
            fileIds: Array.isArray(formState.files)
                ? formState.files.map(file => file.id)
                : [],
            productId: fromSelectObject(formState.productId),
            locationId: fromSelectObject(formState.locationId),
            userId: predefinedState.userOffer
                ? predefinedState.userOffer.user.id
                : null,
            ...predefinedState,
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onUpdate = formState => {
        const { actions, data } = this.props;

        return actions.update({
            ...this.formStateToData(formState),
            id: data.id,
        });
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...this.formStateToData(formState),
        })
            .then(response => {
                history.push(
                    withVariables(
                        ADMIN_USER_OFFERS_MANAGE.path,
                        { id: fromSelectObject(formState.userOfferId) },
                        { tab: TAB_DIAGNOSTIC_REPORTS },
                    )
                );
            });
    }

    onRemove = () => {
        const { actions, data, history } = this.props;

        actions.remove({ id: data.id }) 
            .then(response => {
                history.push(
                    withVariables(
                        ADMIN_USER_OFFERS_MANAGE.path,
                        { id: data.userOffer?.id },
                        { tab: TAB_DIAGNOSTIC_REPORTS },
                    )
                );
                toast('Raport został usunięty');
            })
            .catch(error => {
                toast('Wystąpił błąd, spróbuj ponownie poźniej');
                Logger.error('[UserOfferDiagnosticReport] Failed to delete', error);
                this.setState({
                    isPending: false,
                    error: true,
                });
            });
    }

    onUserNotification = () => {
        const { actions, data } = this.props;

        actions.userNotification({ id: data.id }) 
            .then(response => {
                toast('Notyfikacja została wysłama');
            })
            .catch(error => {
                toast('Wystąpił błąd, spróbuj ponownie poźniej');
                Logger.error('[UserOfferDiagnosticReport] Failed to userNotification', error);
                this.setState({
                    isPending: false,
                    error: true,
                });
            });
    }

    render() {
        const { data, location, history, actions, predefinedState } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="admin-user-offer-diagnostic-report-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        label: 'Autor raportu',
                        value: getUserFullName(data.creator).label,
                    }]}
                    controls={[{
                        visible: Boolean(data),
                        title: 'Usuń',
                        subtitle: 'Raport zostanie usunięty',
                        buttonProps: {
                            onClick: () => this.onRemove(),
                            children: 'Usuń',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data),
                        title: 'Wyślij notyfikację',
                        subtitle: 'Użytkownik otrzyma notyfikację z informacjami o raporcie diagnostycznym',
                        buttonProps: {
                            onClick: () => this.onUserNotification(),
                            children: 'Wyślij',
                        },
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'textarea',
                            name: 'diagnose',
                            label: 'Diagnoza',
                        }, {
                            type: 'textarea',
                            name: 'trainingTarget',
                            label: 'Cel treningowy',
                        }, {
                            type: 'textarea',
                            name: 'homework',
                            label: 'Praca domowa',
                        }, {
                            type: 'select',
                            name: 'offerId',
                            label: 'Oferta',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listOffers(),
                                onMapResponse: response => response.payload[API_RESOURCE_OFFERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: `${element.name}`,
                                }),
                            },
                        }, {
                            type: 'datePicker',
                            name: 'nextEventDate',
                            label: 'Data następnego treningu',
                        }, {
                            type: 'select',
                            name: 'productId',
                            label: 'Produkt następnego treningu',
                            inputProps: {
                                dynamic: true,
                                loadOnOnLoadChange: true,
                                onLoad: query => actions.listProducts({
                                    search: query,
                                    offerId: fromSelectObject(formState.offerId),
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_PRODUCTS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: element.name,
                                }),
                            },
                        }, {
                            type: 'select',
                            name: 'locationId',
                            label: 'Lokacja następnego treninguuu',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listLocations({
                                    page: 1,
                                    perPage: 40,
                                    search: query,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_LOCATIONS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getLocationName(element).label,
                                }),
                            },
                        }, {
                            type: 'select',
                            name: 'leadId',
                            label: 'Prowadzący',
                            isVisible: Boolean(formState.productId && formState.locationId),
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({ 
                                    search: query,
                                    enabled: true,
                                    role: USER_ROLE_TRAINER,
                                    canLeadProductId: fromSelectObject(formState.productId) || data && data.product && data.product.id,
                                    locationId: fromSelectObject(formState.locationId) || data && data.location && data.location.id,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getUserFullName(element).label,
                                }),
                            },
                        }, {
                            type: 'select',
                            name: 'sellProbability',
                            label: 'Prawdopodobieństwo sprzedaży',
                            options: USER_OFFER_SELL_PROPABILITIES.map(option => ({
                                label: option.label,
                                value: option.key,
                            })),
                        }, {
                            type: 'input',
                            name: 'eventRating',
                            label: 'Ocena treningu od adepta (1-10)',
                            isVisible: Boolean(data),
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            type: 'textarea',
                            name: 'eventRatingReason',
                            label: 'Ocena treningu od adepta - powód',
                            isVisible: Boolean(data),
                        }, {
                            type: 'input',
                            name: 'leadRating',
                            label: 'Ocena treningu od trenera (1-10)',
                            isVisible: Boolean(data),
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            type: 'textarea',
                            name: 'leadRatingReason',
                            label: 'Ocena treningu od trenera - powód',
                            isVisible: Boolean(data),
                        }, {
                            type: 'textarea',
                            name: 'comment',
                            label: 'Komentarz trenera',
                        }, {
                            type: 's3FileMultiUpload',
                            name: 'files',
                            label: 'Pliki',
                            isVisible: Boolean(data),
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_ADMIN_FILE_PRESIGN
                                        .replace('{type}', 'userOfferDiagnosticReportFile'),
                                },
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}